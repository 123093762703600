.App {
  text-align: center;
  padding-top: 40px;
  font-family: 'Poppins', sans-serif;
}

header {
  max-width: 900px;
  margin: auto auto;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header .logo {
  width: 150px;
}

header .social-icons a {
margin: 0 8px 0 0;
}

header nav ul, footer nav ul {
  display: flex;
  padding:0;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
}

header nav ul li, footer nav ul li {
  display: inline-block;
  height: 25px;
  margin:0 16px;
  font-size: 18px;
  padding: 0 auto;
}

footer nav ul li {
  font-size: 14px;
}

header nav ul .nav--active a, footer nav ul .nav--active a{
  /*border-bottom: 1px rgb(221, 221, 221) dashed;*/
  color: black;
  text-decoration: underline;
}

header nav ul li a, footer nav ul li a{
  text-decoration: none;
  color: rgb(90, 90, 90);
}

.header-hero {
  height:300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-hero h1 {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(235deg, #d6da00, #edff8600 70.71%),
              linear-gradient(115deg, #038544, #b3e5be00 70.71%),
              linear-gradient(355deg, #03da7d, #9785ff00 70.71%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.header-hero .header-tagline {
  padding: 20px 15%;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  color: #5b5b5b;
  font-weight: 500;
}


.routes {
  min-height: 500px;
  /*display: flex;
  width: 100%;
  justify-items: center;
  justify-content: center;
  */
}

.routes > div {
  /*width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-items: center;*/
}

.entries, .page, .page_home {
  height: 100%;
  width: 100%;
  padding: 0;
}

.entries {
  /*background-color: rgb(50, 50, 50); */
  display:flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-items: center;
  padding: 40px 0;
}

.entries-separator {
  height: 0px;
  width: 100%;
  background: linear-gradient(0deg, rgba(43,43,43,1) 1%, rgba(43,43,43,0) 71%);
}

.entry {
  width: 100%;
  max-width: 900px;
  margin: 0px auto 30px;
  padding: 0;
  /* border-top: 1px dotted rgb(209, 209, 209);
  border-bottom: 1px dotted rgb(209, 209, 209); */
  border-radius: 0;
  box-sizing: border-box;
  text-decoration: none;
  background-size: cover;
  background-position: center;
  position: relative;
}
/*
.entry:hover {
  border-top: 1px solid rgb(128, 128, 128);
  border-bottom: 1px solid rgb(128, 128, 128);
}
*/
.entry .entry_container {
  padding: 50px 100px;
}

.entry .entry_uneven {
  text-align: left;
  /* background: linear-gradient(to left, #00000000, rgba(43,43,43,1)); */
}

.entry .entry_even {
  text-align: right;
  /* background: linear-gradient(to right, #00000000, rgba(43,43,43,1)); */
}

.entry .entry__title{
  text-transform: none;
  margin:0;
  color: rgb(255, 255, 255);  
  font-size: 26px;
  font-weight: bold;
  display: inline-flex;
  flex-direction: row-reverse;
}

.entry .entry_even .entry__title{
  text-transform: none;
  margin:0;
  color: rgb(255, 255, 255);  
  font-size: 26px;
  font-weight: bold;
  display: inline-flex;
  flex-direction: row;
}

.entry .entry__title .entry__title_logo img {
  width: 30px;
  height: 30px;
  margin: auto auto;
}

.entry .entry__title .entry__title_logo {
  display: inline-flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 25px;
  margin: 0 10px 0 0;
}

.entry .entry_even .entry__title .entry__title_logo{
  margin: 0 0 0 10px;
}

.entry .entry__title .entry__title_text {
  line-height: 50px;
}

.entry .entry__title .entry__icon {
  margin-right: 10px;
}

.entry .entry__description{
  color: rgb(255, 255, 255);
  font-size: 16px;
  margin: 8px 0 0 0;
}

.entry .entry__button{
  color:white;
  font-size: 16px;
  margin: 16px 0 0 0;
}

.page__title {
  font-weight: bold;
}

footer {
  max-width: 900px;
  margin: auto auto;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gradient-button {
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  height: 50px;
  padding: 0 20px;
  font-size: 16px;
  background: linear-gradient(-170deg, #edff86,#5fc074);
  color: rgb(255, 255, 255);
  border: 2px solid rgba(255, 255, 255, 0.729);
  background-size: 200% 200%;
  border-radius: 25px;
  cursor: pointer; 
}

.gradient-button:hover {
  background: linear-gradient(45deg, #d6da0092,#03da7d92, #03854492);
  background-size: 200% 200%;
  animation: gradientAnimation 2s ease infinite;
}

.gradient-button__light {
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  height: 40px;
  padding: 0 20px;
  font-size: 16px;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.8);
  background: none;
  border-radius: 20px;
  cursor: pointer; 
}

.gradient-button__light:not(.gradient-button__light__disabled):hover {
  background: linear-gradient(-90deg, #d6da0092,#03da7d92, #03854492);
  color: white;
  background-size: 200% 200%;
  border: 2px solid rgba(255, 255, 255);
  animation: gradientAnimation 2s ease infinite;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.gradient-button__light__disabled {
  color: white;
  border: 2px solid white;
  opacity: 0.4;
  cursor: auto;
}


@media (max-width: 500px) {

  header, footer {
    padding: 0px 20px;
  }

  .header-container {
    flex-direction: column;
  }

  .header-hero {
    height:240px;
  }

  .header-hero .header-tagline {
    padding: 20px 15%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #5b5b5b;
    font-weight: 500;
  }

  .entry .entry_container {
    padding: 50px 50px;
  }

  .page {
    width: auto;
    padding: 0px 20px;
  }

}